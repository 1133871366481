<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{ title }}</h4>
                        <p class="mb-0">{{ desc1 }}<br> {{ desc2 }}</p>
                    </div>
                    <b-button variant="primary pl-5 pr-5" class="mt-2 mr-1" @click="collectGrowthDb" v-if="showlist">로켓그로스 상품DB 수집</b-button>
                </div>
            </div>
            <div class="col-lg-12">
                <img v-if="showspinner" style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                <div v-if="showspinner1" class="d-flex justify-content-center">
                    <div><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div>
                </div>
            </div>

            <div class="col-lg-12" v-if="showlist">
                <div class="p-3">
                    <div class="row">
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showtotal">
                        전체 <b-badge variant="primary" class="ml-2">{{total}}</b-badge>
                        </b-button>
                        <b-button variant="outline-danger" class="mb-1 mr-2" @click="showa">
                        아이템루저<b-badge variant="danger" class="ml-2">{{아이템루저}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showb">
                        뱃지없음<b-badge variant="warning" class="ml-2">{{뱃지없음}}</b-badge>
                        </b-button>
                        <!-- <b-button variant="outline-success" class="mb-1 mr-2" @click="showc">
                        구매정보있음<b-badge variant="success" class="ml-2">{{구매정보있음}}</b-badge>
                        </b-button> -->
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showd">
                        구매정보없음<b-badge variant="secondary" class="ml-2">{{구매정보없음}}</b-badge>
                        </b-button>
                        <b-button variant="outline-success" class="mb-1 mr-2" @click="showe">
                        매입정보없음<b-badge variant="success" class="ml-2">{{매입정보없음}}</b-badge>
                        </b-button>
                    </div>
                </div>
            </div>


            <div class="col-lg-12" v-if="showlist">
                <CDataTable :headers="headers" :items="productLists" v-model="tableSelectedItem" :show-select="true" itemKey="_id" style="margin-bottom: 10px">
                    <template v-slot:상품명="{item}">
                        <div class="d-flex align-items-center" v-b-modal="'modalpurchase' + item._id">
                            <img :src="item.옵션이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                            <div>
                                {{ item.상품명 }}
                                <br><small>바코드 : {{ item.바코드 }}</small><br>
                                <small>노출Id : {{ item.노출ID }}</small><br>
                                <small>옵션Id : {{ item.옵션ID }}</small><br>
                                <small>승인상태 : {{ item.승인상태 }}</small>
                            </div>
                        </div>
                    </template>
                    <template v-slot:구매정보="{item}">
                        <b-icon v-b-modal="'modalpurchase' + item._id" icon="cart-check" scale="2" :variant="classPurchaseInfo(item)"></b-icon>

                        <b-modal :id="'modalpurchase' + item._id" size="xl" title="구매정보" ok-title="저장" cancel-title="취소" @ok="savePurchaseInfo($event,item)" @cancel="cancelPurchaseInfo($event,item)">
                            <div>{{ item.productName }}</div>
                            <div class="row" v-if="showPurchase">
                                <div class="col-12">
                                    <div class="table-responsive">
                                        <table id="datatable" class="table data-table table-striped dataTable">
                                            <thead>
                                                <tr class="ligth ligth-data">
                                                    <th style="min-width:200px;font-size:90%">제품정보</th>
                                                    <th style="font-size:90%">상품매칭</th>
                                                    <th style="max-width:300px;overflow:hidden;text-overflow: ellipsis;font-size:90%">구매링크</th>
                                                    <th style="font-size:90%">옵션1_중국어</th>
                                                    <th style="font-size:90%">옵션2_중국어</th>
                                                    <th style="font-size:90%">구성수량</th>
                                                    <!-- <th>구매가격</th> -->
                                                    <!-- <th>구매재고</th> -->
                                                    <th style="font-size:90%">기본요청사항</th>
                                                    
                                                    <th style="font-size:90%">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(list,idx1) in item.구매정보" :key="idx1" class="tableTr">
                                                    <td style="min-width:200px;font-size:90%">
                                                        <div class="d-flex align-items-center">
                                                            <img :src="list.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image" v-if="list.등록이미지">
                                                            <div v-if="list.상품명">
                                                                {{list.상품명}}
                                                                <p class="mb-0"><small>{{list.바코드}}</small></p>
                                                            </div>
                                                            <div v-else>
                                                                <input type="text" class="form-control" placeholder="상품명입력" v-model="list.상품명init" @change="addProductName(list)">
                                                                <p class="mb-0"><small>{{list.바코드}}</small></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style="font-size:90%"><div :class="matchclass(list.매칭상태)">{{list.매칭상태}}</div></td>
                                                    <td style="max-width:300px;overflow:hidden;text-overflow: ellipsis;font-size:90%">
                                                        <div v-if="list.구매링크 !== undefined">
                                                            <a :href="list.구매링크" target="_blank">{{list.구매링크}}</a>
                                                            <div v-if="list.showspinner">
                                                                <img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <input type="text" class="form-control" placeholder="구매링크입력" v-model="list.구매링크init" @change="matchoption1(item.구매정보,idx1,list.구매링크init)">
                                                        </div>
                                                    </td>
                                                    <td style="font-size:90%">
                                                        <div v-if="list.옵션이미지 !== undefined">
                                                            <img :src="list.옵션이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                        </div>
                                                        <div v-if="list.옵션1_중국어 !== undefined">
                                                            {{list.옵션1_중국어}}
                                                        </div>
                                                        <div v-else>
                                                            <b-dropdown class="my-class" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                                                <template #button-content>
                                                                &#x1f50d;<span class="sr-only">Search</span>
                                                                </template>
                                                                <b-dropdown-item href="#" v-for="a,i in list.상품매칭.옵션1_중국어" :key="i" @click="option1matching(a,list)">
                                                                    <div class="d-flex align-items-center">
                                                                        <img v-if="a.imageUrl.length > 0" :src="a.imageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                        <div>
                                                                            {{a.name}}
                                                                        </div>
                                                                    </div>
                                                                </b-dropdown-item>
                                                            </b-dropdown>
                                                        </div>
                                                    </td>
                                                    <td style="font-size:90%">
                                                        <div v-if="list.옵션2_중국어 !== undefined">
                                                            {{list.옵션2_중국어}}
                                                        </div>
                                                        <div v-else>
                                                            <b-dropdown class="my-class" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                                                <template #button-content>
                                                                &#x1f50d;<span class="sr-only">Search</span>
                                                                </template>
                                                                <b-dropdown-item href="#" v-for="a,i in list.상품매칭.옵션2_중국어" :key="i" @click="option2matching(a,list)">
                                                                    <div class="d-flex align-items-center">
                                                                        <img v-if="a.imageUrl.length > 0" :src="a.imageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                        <div>
                                                                            {{a.name}}
                                                                        </div>
                                                                    </div>
                                                                </b-dropdown-item>
                                                            </b-dropdown>
                                                        </div>
                                                    </td>
                                                    <td style="font-size:90%">
                                                        <div v-if="!editQty" @click="editQty = true">
                                                            {{list.판매구성수량}}
                                                        </div>
                                                        <div v-else>
                                                            <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="list.판매구성수량" style="text-align:center;" v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'" @keyup.enter="editQtyEnter" @change="editQty = false">
                                                        </div>
                                                    </td>
                                                    <!-- <td>
                                                        {{list.구매가격}}
                                                        <small>({{list.이전구매가격}})</small>
                                                    </td> -->
                                                    <!-- <td>{{list.구매재고}}</td> -->
                                                    <td style="font-size:90%">
                                                        <div :class="reqclass(list.요청사항)" v-b-modal="'modalreq' + idx1" v-if="idx1 == 0">{{list.요청사항}}</div>
                                                        <b-modal :id="'modalreq' + idx1" size="md" title="구매요청사항" ok-title="요청내용저장" cancel-title="취소" @ok="savereq($event,list,idx1)">
                                                            <div class="row"> 
                                                                <div class="col-md-12 mt-4 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>1. 구매요청수량</h6>
                                                                    <div class="custom-control-inline ml-3">
                                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="list.구매요청수량" style="text-align:center;">
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>2. 판매구성수량</h6>
                                                                    <div class="custom-control-inline ml-3">
                                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="list.판매구성수량" style="text-align:center;" v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'">
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>3. A-B번들 묶음구성여부</h6>
                                                                    <div class="custom-control ml-3 custom-switch custom-control-inline d-flex align-items-center">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch111" v-model="list.묶음구성여부" disabled>
                                                                        <label class="custom-control-label" for="customSwitch111">&nbsp;</label>
                                                                        <input v-if="list.묶음구성여부" type="number" :min="0" class="form-control ml-3" placeholder="종류갯수" aria-label="" v-model="list.묶음구성수량" style="text-align:center;" v-b-tooltip.bottom="'3종의 제품을 묶음 구성하는 경우 3으로 작성'" disabled>
                                                                        <!-- <input v-if="list.묶음구성여부" type="text" class="form-control ml-3" placeholder="묶음대상바코드번호" aria-label="" v-model="list.바코드" style="text-align:center;" v-b-tooltip.bottom="'묶음구성할 대상의 바코드를 입력해 주세요.'"> -->
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>3. 도착지</h6>
                                                                    <div class="custom-control custom-radio custom-control-inline ml-3">
                                                                       <input type="radio" id="customRadio3" name="customRadio-3" class="custom-control-input" v-model="list.요청내역.destination" value="coupang">
                                                                       <label class="custom-control-label" for="customRadio3">쿠팡센터</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                       <input type="radio" id="customRadio4" name="customRadio-3" class="custom-control-input" v-model="list.요청내역.destination" value="customer">
                                                                       <label class="custom-control-label" for="customRadio4">화주</label>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>4. 출고형태</h6>
                                                                    <div class="custom-control custom-radio custom-control-inline ml-3">
                                                                       <input type="radio" id="customRadio2" name="customRadio-44" class="custom-control-input" v-model="list.요청내역.exportType" value="box">
                                                                       <label v-if="!list.요청내역.destination || list.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio2">밀크런 택배</label>
                                                                       <label v-if="list.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio2">택배</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                       <input type="radio" id="customRadio1" name="customRadio-44" class="custom-control-input" v-model="list.요청내역.exportType" value="pallet">
                                                                       <label v-if="!list.요청내역.destination || list.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio1">밀크런 파렛트 (￦35,000/pallet)</label>
                                                                       <label v-if="list.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio1">파렛트 (￦20,000/pallet)</label>
                                                                    </div>
                                                                </div>



                                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>5. 통관정보</h6>
                                                                    <div class="custom-control-inline ml-3">
                                                                        <input type="text" class="form-control" placeholder="신고영문명" aria-label="" v-model="list.신고영문명" style="text-align:center;" v-b-tooltip.bottom="'제품의 신고영문명을 간단히 작성해주세요. ex) 신고영문명 : shoes'">
                                                                    </div>
                                                                    <div class="custom-control-inline">
                                                                        <input type="text" class="form-control" placeholder="HS-CODE" aria-label="" v-model="list.HSCODE" style="text-align:center;" v-b-tooltip.bottom="'통관할 제품의 HS-CODE를 작성해주세요 ex) HS-CODE : 6401.10-1000'">
                                                                    </div>
                                                                    <a href="https://www.bandtrass.or.kr/hsnavi.do?page=F&site=" target="_blank"><u>HS-CODE 검색</u></a>
                                                                </div>


                                                               <div class="col-md-12 mt-3">
                                                                   <h6>6. 검수 및 포장</h6>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch6" v-model="list.요청내역.default">
                                                                       <label class="custom-control-label" for="customSwitch6">기본검수/분류/포장/포장자재 (￦200)</label>
                                                                   </div>
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="list.요청내역.barcode" @change="changeBarcode(list)">
                                                                       <label class="custom-control-label" for="customSwitch2" v-b-tooltip.bottom="'바코드라벨이 기본적으로 되어 있거나, 불필요한 경우 OFF해주세요.'">바코드라벨 (Made in China,한글표시사항 기본포함) (￦100)</label>
                                                                   </div>
                                                                   <div class="custom-control custom-switch custom-control-inline" v-if="!list.요청내역.barcode">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch212" v-model="list.요청내역.sticker">
                                                                       <label class="custom-control-label" for="customSwitch212" v-b-tooltip.bottom="'바코드라벨 미부착 시 반드시 원산지가 표시되어 있어야 합니다. 불필요한 경우 OFF해주세요.'">원산지스티커 (￦100)</label>
                                                                   </div>
                                                                   <!-- <div class="custom-control custom-switch custom-control-inline" v-if="list.요청내역.barcode">
                                                                       <input type="text" class="form-control" placeholder="바코드입력" aria-label="" v-model="list.바코드" style="text-align:center;" >
                                                                   </div> -->
                                                               </div>

                                                               <div class="col-md-12 mt-0" v-if="list.요청내역.default">
                                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'포장 방법을 쿠패스에 위임해서 진행함. (적합하지 않는 경우 추후 사입 시에는 얼마든지 변경가능하며, 판단 미스에 대한 별도의 보상은 없음.)'">
                                                                       <input type="radio" id="customRadio88" name="customRadio-4" class="custom-control-input" value="auto" v-model="packageMethod" @change="changePackage(list)">
                                                                       <label class="custom-control-label" for="customRadio88">포장 방법 위임</label>
                                                                    </div>
                                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품의 원상태 포장상태 그대로 진행'">
                                                                        <input type="radio" id="customRadio5" name="customRadio-4" class="custom-control-input" value="noPackage" v-model="packageMethod" @change="changePackage(list)">
                                                                        <label class="custom-control-label" for="customRadio5">재포장 생략</label>
                                                                    </div>
                                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이는 투명 OPP 봉투로 재포장 진행'">
                                                                        <input type="radio" id="customRadio6" name="customRadio-4" class="custom-control-input" value="opp" v-model="packageMethod" @change="changePackage(list)">
                                                                        <label class="custom-control-label" for="customRadio6">투명 OPP 봉투 포장 (무료)</label>
                                                                    </div>
                                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이지 않는 불투명 LDPE 봉투로 재포장 진행'">
                                                                        <input type="radio" id="customRadio77" name="customRadio-4" class="custom-control-input" value="LDPE" v-model="packageMethod" @change="changePackage(list)">
                                                                        <label class="custom-control-label" for="customRadio77">불투명 LDPE 봉투 포장 (무료)</label>
                                                                    </div>

                                                                </div>
                                                                <div class="col-md-12 mt-0">
                                                                   <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'박스 겉면에 있는 중국어를 가리는 작업 진행'">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91" v-model="list.요청내역.masking">
                                                                        <label class="custom-control-label" for="customSwitch91">중국어 마스킹 (무료)</label>
                                                                    </div>
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch9" v-model="list.요청내역.airpacking">
                                                                        <label class="custom-control-label" for="customSwitch9">에어캡 포장 (실비)</label>
                                                                    </div>
                                                               </div>

                                                               <div class="col-md-12 mt-0">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91a" v-model="list.요청내역.sewing">
                                                                        <label class="custom-control-label" for="customSwitch91a">원산지라벨 봉제 (6000원/시간)</label>
                                                                        <div class="warning2 ml-2" style="font-weight: 500">* 의류 수입 시 원산지 라벨은 필수 사항입니다.</div>
                                                                    </div>
                                                               </div>
                                                               <div class="col-md-12 mt-0">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91b" v-model="list.요청내역.stamp">
                                                                        <label class="custom-control-label" for="customSwitch91b">원산지 도장 (6000원/시간)</label>
                                                                        <div class="warning2 ml-2" style="font-weight: 500">* 신발류 수입 시 제품에 원산지 라벨봉제 또는 원산지 도장은 필수 사항입니다.</div>
                                                                    </div>
                                                               </div>
                                                               <div class="col-md-12 mt-0">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91c" v-model="list.요청내역.tag">
                                                                        <label class="custom-control-label" for="customSwitch91c">원산지 택(태그) (6000원/시간)</label>
                                                                        <div class="warning2 ml-2" style="font-weight: 500">* 가방류 수입 시 원산지 라벨봉제 또는 원산지 택 표기가 필수 사항입니다.</div>
                                                                    </div>
                                                               </div>
                                                               
                                                                <div class="col-md-12 mt-3 d-flex align-items-center justify-contents-start">
                                                                    <h6>7. 한글표시사항</h6>
                                                                </div>
                                                                <div class="col-md-12 mb-1" v-if="!list.요청내역.barcode">
                                                                    <div class="custom-control-inline w-50 d-flex align-items-center mb-2 d-flex flex-column border">
                                                                        <div class="warning2" style="font-weight: 500;font-size:x-large">※ 바코드라벨을 부착하지 않는 경우,</div>
                                                                        <div class="warning2" style="font-weight: 500;font-size:x-large" v-if="list.요청내역.destination == 'coupang'">쿠팡센터 직납 시 사전에 제품패키지 겉에 반드시 바코드가 부착되거나 새겨져 있어야 합니다.</div>
                                                                        <div class="warning2" style="font-weight: 500;font-size:x-large" v-if="list.요청내역.destination == 'customer'">반드시 원산지표시가 되어 있어야 합니다.</div>
                                                                       <!-- <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="list.바코드" style="text-align:center;"  v-b-tooltip.right="'ex) S00012341234'"> -->
                                                                   </div>
                                                                </div>
                                                                <div class="col-md-12 mt-2 mb-1" v-if="list.요청내역.barcode">
                                                                    <div class="custom-control-inline w-50 d-flex align-items-center mb-2">
                                                                       <label class="w-50 ml-0">바코드번호</label>
                                                                       <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="list.바코드" style="text-align:center;"  v-b-tooltip.right="'ex) S00012341234'" disabled>
                                                                   </div>
                                                                    <div class="name-input">
                                                                       <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                           <label class="w-50 ml-0">제품명</label>
                                                                           <input type="text" class="form-control" placeholder="제품명" aria-label="" v-model="list.상품명" style="text-align:center;"  v-b-tooltip.right="'ex) 쿠플러스 예쁜접시, 10인치'" disabled>
                                                                       </div>
                                                                       <div class="warning2" style="font-weight: 500">* 쿠팡에 등록한 상품명 + 옵션명을 같이 입력해주세요</div>
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">수입원/판매원</label>
                                                                       <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="list.한글표시사항['수입원/판매원']" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">제조원</label>
                                                                       <input type="text" class="form-control" placeholder="제조원" aria-label="" v-model="list.한글표시사항.제조원" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명OEM'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">제조국</label>
                                                                       <input type="text" class="form-control" placeholder="제조국" aria-label="" v-model="list.한글표시사항.제조국" style="text-align:center;"  v-b-tooltip.right="'ex) 중국'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">내용량</label>
                                                                       <input type="text" class="form-control" placeholder="내용량" aria-label="" v-model="list.한글표시사항.내용량" style="text-align:center;"  v-b-tooltip.right="'ex) 300ml'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">원료명및성분명</label>
                                                                       <input type="text" class="form-control" placeholder="원료명및성분명(재질)" aria-label="" v-model="list.한글표시사항['원료명및성분명(재질)']" style="text-align:center;"  v-b-tooltip.right="'ex) 도자기제'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">상품유형</label>
                                                                       <input type="text" class="form-control" placeholder="상품유형" aria-label="" v-model="list.한글표시사항.상품유형" style="text-align:center;"  v-b-tooltip.right="'ex) 주방용품'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">사용시주의사항</label>
                                                                       <input type="text" class="form-control" placeholder="사용시주의사항" aria-label="" v-model="list.한글표시사항.사용시주의사항" style="text-align:center;"  v-b-tooltip.right="'ex) 용도 이외에 사용금지'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">사용기준</label>
                                                                       <input type="text" class="form-control" placeholder="사용기준" aria-label="" v-model="list.한글표시사항.사용기준" style="text-align:center;"  v-b-tooltip.right="'ex) 14세 이상'">
                                                                   </div>
                                                                </div>

                                                                <div class="col-md-12" style="display: flex">
                                                                    <h6 class="mt-4 mb-1">[ 기타요청사항 ]</h6>
                                                                    <div class="mt-3" style="margin-left: 8px; position: relative; top: 4px; color: #e83e8c">(예: 1688제품 겉박스 중국어 있는부분 작업 요청 내용, 실사, 사이즈, 무게, 센터 입고요청, 신발도장, 의류봉제 등, OPP/LDPE/뽁뽁이 일반검수/정밀검수, 시간이 걸리는 별도의 작업은 6000/시간의 작업비 발생)</div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="list.요청내역.reqcontent"></textarea>
                                                                </div>

                                                            </div>
                                                        </b-modal>

                                                    </td>
                                                    
                                                    <td style="font-size:90%">
                                                        <div class="d-flex justify-content-center list-action">
                                                            <!-- <a class="badge badge-primary mr-2" data-toggle="tooltip" v-b-tooltip.top title="복사" href="javascript:void(0)" @click="copyReq(item.구매정보)">
                                                                <i class="ri-file-copy-line mr-0"></i>
                                                            </a> -->
                                                            <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="옵션재매칭" href="javascript:void(0)" @click="rematching(item.구매정보,list,idx1)">
                                                                <i class="ri-pencil-line mr-0"></i>
                                                            </a>
                                                            <a class="badge badge-secondary mr-2" data-toggle="tooltip" v-b-tooltip.top title="Reset" href="javascript:void(0)" @click="reset(list)">
                                                                <i class="ri-eye-line mr-0"></i>
                                                            </a>
                                                            <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deleteitem(item.구매정보,idx1,item)">
                                                                <i class="ri-delete-bin-line mr-0"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-12 mb-0">
                                    <b-button @click="copyReq(item.구매정보)" variant="outline-primary" class="text-center">+ 묶음구성추가</b-button>
                                </div>
                            </div>
                        </b-modal>
                    </template>
                    <template v-slot:판매링크="{item}">
                        <a :href="item.판매링크"
                            target="_blank">
                            <b-icon icon="link" scale="2" variant="primary"></b-icon>
                        </a>
                    </template>
                    <template v-slot:아이템위너="{item}">{{item.아이템위너 ? 'Y' : 'N'}}</template>
                    <template v-slot:뱃지="{item}">{{item.뱃지 ? 'Y' : 'N'}}</template>
                    <template v-slot:사이즈="{item}">
                        <div v-b-modal="'size' + item._id">
                            {{item.사이즈}}
                        </div>

                        <b-modal :id="'size' + item._id" size="md" title="등록사이즈" ok-only>
                            <div class="row"> 
                                <div class="col-md-12 mt-4 mb-1">
                                    <h6>1. 사이즈 (mm)</h6>
                                    <div class="custom-control-inline ml-3" v-if="item.사이즈정보">
                                        {{item.사이즈정보.width}} x {{item.사이즈정보.length}} x {{item.사이즈정보.height}}
                                    </div>
                                    <h6>2. 무게 (g)</h6>
                                    <div class="custom-control-inline ml-3" v-if="item.사이즈정보">
                                        {{item.사이즈정보.weight}}
                                    </div>
                                </div>
                            </div>
                        </b-modal>
                    </template>
                    <template v-slot:수수료="{item}">
                        <div v-b-modal="'commision' + item._id">
                            {{parseInt(item.수수료)}}
                        </div>

                        <b-modal :id="'commision' + item._id" size="md" title="수수료내역" ok-only>
                            <div class="row"> 
                                <div class="col-md-12 mt-4 mb-1">
                                    <h6>1. 풀필먼트수수료(배송비)</h6>
                                    <div class="custom-control-inline ml-3">
                                        {{item.풀필먼트수수료}}
                                    </div>
                                    <h6>2. 입출고수수료</h6>
                                    <div class="custom-control-inline ml-3">
                                        {{item.입출고수수료}}
                                    </div>
                                    <h6>3. 판매수수료(판매수수료율)</h6>
                                    <div class="custom-control-inline ml-3">
                                        {{parseInt(item.판매수수료)}}({{item.판매수수료율}}%)
                                    </div>
                                </div>
                            </div>
                        </b-modal>
                    </template>
                    <template v-slot:매입가="{item}">
                        <div v-b-modal="'modalImport' + item._id">{{item.매입가 ? item.매입가 : ""}}</div>
                        <b-button v-if="!item.매입가" variant="outline-primary" class="mb-1 mr-2" v-b-modal="'modalImport' + item._id">입력</b-button>

                        <b-modal :id="'modalImport' + item._id" size="md" title="매입정보" ok-title="저장" cancel-title="취소" @ok="saveImport($event,item)" @cancel="cancelImport($event,item)">
                            <p>매입가는 부가세가 포함된 전체 매입가로 입력해 주세요</p>
                            <div class="row" v-if="showImport">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table id="datatable" class="table data-table table-striped dataTable text-center" style="font-size:90%">
                                            <thead>
                                                <tr class="ligth ligth-data">
                                                    <th style="font-size:90%">Date</th>
                                                    <th style="font-size:90%">매입가</th>
                                                    <th style="font-size:90%">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(list,idx) in item.매입정보" :key="idx">
                                                    <td>
                                                        <div v-if="!editmode">
                                                            {{list.date}}
                                                        </div>
                                                        <div v-else>
                                                            <input type="date" class="form-control ml-1 text-center" v-model="list.date" @keyup.enter="changeImportInfo($event)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div v-if="!editmode">
                                                            {{list.매입가}}
                                                        </div>
                                                        <div v-else>
                                                            <input type="number" class="form-control ml-1 text-center" v-model="list.매입가" @keyup.enter="changeImportInfo($event)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <!-- <a class="badge badge-secondary mr-2" data-toggle="tooltip" v-b-tooltip.top title="Edit" href="javascript:void(0)" @click="editImport()">
                                                            <i class="ri-eye-line mr-0"></i>
                                                        </a> -->
                                                        <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deleteImport(item,idx)">
                                                            <i class="ri-delete-bin-line mr-0"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <div class="d-flex justify-content-start mt-2 ml-4 mb-2">
                                                        <b-button @click="addImportInfo(item)" variant="outline-primary" class="text-center">+ 추가</b-button>
                                                        <b-button @click="editImport()" variant="outline-secondary" class="text-center ml-2">편집</b-button>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- <div class="d-flex justify-content-between">
                                        <button type="button" class="btn btn-success mr-2 mb-4" @click="resetAutoList">전체삭제</button>
                                        <button type="button" class="btn btn-primary mr-2 mb-4" @click="collectAll">전체수집</button>
                                    </div> -->
                                </div>
                            </div>
                        </b-modal>
                    </template>
                    <template v-slot:마진="{item}">{{item.매입가 ? item.마진 : ''}}</template>
                    <template v-slot:마진율="{item}"><div v-if="item.매입가">{{item.마진율*100}}%</div><div v-if="item.매입가">{{parseInt(item.ROI*100)}}%</div><div v-if="item.매입가">{{parseInt(item.ROAS*100)}}%</div></template>
                    <!-- <template v-slot:ROI="{item}">
                        <div class="d-flex align-items-center list-action">
                            <a class="badge bg-primary mr-2" data-toggle="tooltip" v-b-tooltip.top title="바코드출력" href="javascript:void(0)">
                                <i class="ri-printer-line mr-0"></i>
                            </a> 
                            <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="관리삭제" href="javascript:void(0)" @click="delManageItemBy(item,item._id)">
                                <i class="ri-delete-bin-line mr-0"></i>
                            </a> 
                        </div>
                    </template> -->
                </CDataTable>
            </div>
            
                        
            <div class="col-lg-12 mb-3" v-if="showlist">
                <!-- <button type="button" class="btn btn-primary mr-3" @click="search(true)">재검색</button> -->
                <button type="reset" @click="excelDownFunc" class="btn btn-success mr-2 mb-3">상품DB 다운로드</button>
                <button type="reset" class="btn btn-secondary mr-2 mb-3" @click="delManageItems">숨김처리</button>
                <button type="reset" class="btn btn-primary mr-2 mb-3" v-b-modal.addManage>숨김처리취소</button>
            </div>

            <b-modal :id="'addManage'" size="md" title="숨김처리취소" ok-only>
                <button type="reset" @click="importHideProducts" class="btn btn-primary mr-2 mb-3">숨김상품 불러오기</button>
                <div class="row">
                    <img v-if="showspinner2" style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                </div>
                <div class="row" v-if="showHideProducts">
                    <div class="col-12 mt-3">
                        <div class="table-responsive">
                            <table class="table data-table table-striped dataTable">
                                <thead>
                                    <tr class="ligth ligth-data">
                                        <th style="min-width:200px;font-size:90%">제품정보</th>
                                        <th style="font-size:90%">숨김처리취소</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(list,index) in searchProducts" :key="index">
                                        <td style="min-width:300px;">
                                            <div class="d-flex align-items-center" v-b-modal="'modalpurchase' + index">
                                                <img :src="list.optionImageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                <div>
                                                    {{ list.productName }}
                                                    <p class="mb-0"><small>노출Id : {{ list.productId }}</small></p>
                                                    <p class="mb-0"><small>옵션Id : {{ list.vendorItemId }}</small></p>
                                                    <p class="mb-0"><small>승인상태 : {{ list.statusName }}</small></p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <b-button @click="addSearchProduct(list,index)" variant="outline-primary" class="text-center">숨김처리취소</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-modal>



                        <div class="mb-4 ml-1 d-none" id="printMe8">
                            <div v-for="index in parseInt(출력수량)" :key="index">
                                <div class="row d-flex justify-content-center ml-1"
                                    :style="barcodegap(index, parseInt(출력수량))">
                                    <div class="col-12 d-flex flex-left mb-0"
                                        style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;display:flex;justify-content: space-between;">
                                        <div class="mb-0" style="font-size:90%;color:black;">{{ 원산지 }}</div>
                                    </div>
                                    <div class="col-12 text-center d-flex justify-content-center mt-0 mb-0"
                                        style="margin-top:0px;margin-bottom:0px">
                                        <barcode :value="출력바코드" :tag="bctag" height="50"
                                            :options="{ lineColor: '#0275d8', text: 'Scan', margin: 0 }"></barcode>
                                    </div>
                                    <div class="col-12 mt-0 mb-0"
                                        style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:5px;">
                                        <span :style="disablekr">{{ 출력상품명 }}</span><br>
                                        <span :style="enablekr">1. 상품명 : {{ 출력상품명 }}</span><br>
                                        <span :style="enablekr">2. 수입원/판매원 : {{ 한글표시사항['수입원/판매원'] }}</span><br>
                                        <span :style="enablekr">3. 제조원 : {{ 한글표시사항.제조원 }}</span><br>
                                        <span :style="enablekr">4. 제조국 : {{ 한글표시사항.제조국 }}</span><br>
                                        <span :style="enablekr">5. 내용량 : {{ 한글표시사항.내용량 }}</span><br>
                                        <span :style="enablekr">6. 원료명및성분명(재질) : {{ 한글표시사항['원료명및성분명(재질)'] }}</span><br>
                                        <div style="display: flex;justify-content: space-between;">
                                            <div>
                                                <span :style="enablekr">7. 상품유형 : {{ 한글표시사항.상품유형 }}</span><br>
                                                <span :style="enablekr">8. 사용시주의사항 : {{ 한글표시사항.사용시주의사항 }}</span><br>
                                                <span :style="enablekr">9. 사용기준 : {{ 한글표시사항.사용기준 }}</span><br>
                                            </div>
                                            <div :style="printkc">
                                                <img style="max-width:60%;"
                                                    :src="require('../../../assets/images/small/KcMark.jpg')">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import VueBarcode from 'vue-barcode';
import CDataTable from "@/components/common/CDataTable";
export default {
    name: 'Listproduct',
    data() {
        return {
            headers: [
                {text: '상품정보', value: '상품명', align: 'center', width: 500, isSlot: true},
                {text: '구매관리', value: '구매정보', align: 'center', width: 100, isSlot: true},
                {text: '판매링크', value: '판매링크', align: 'center', width: 100, isSlot: true},
                {text: '아이템위너', value: '아이템위너', align: 'center', width: 100, isSlot: true},
                {text: '뱃지', value: '뱃지', align: 'center', width: 100, isSlot: true},
                {text: '사이즈', value: '사이즈', align: 'center', width: 100, isSlot: true},
                {text: '판매가', value: '판매가', align: 'center', width: 100, isSlot: false},
                {text: '할인쿠폰', value: '할인쿠폰', align: 'center', width: 100, isSlot: false},
                {text: '수수료', value: '수수료', align: 'center', width: 100, isSlot: true},
                {text: '매입가', value: '매입가', align: 'center', width: 100, isSlot: true},
                {text: '마진', value: '마진', align: 'center', width: 100, isSlot: true},
                {text: '마진율\nROI\nEnd ROAS', value: '마진율', align: 'center', width: 100, isSlot: true},
                // {text: 'Actions', value: '', align: 'center', width: 100, isSlot: true},
            ],
			tableSelectedItem: [],

            showspinner2:false,
            showHideProducts:false,
            showPurchase:true,

            packageMethod : 'auto',
            searchProducts:[],
            addProducts:[],
            노출상품번호:'',
            등록상품번호:'',
            옵션번호:'',
            purchaseItem : {},
            isCollapseOpen : false,
            allchecked:false,

            PurchaseMappingSearch: false,
            searchPurchaseMapping: '',
            loadingtext: '',

            title: "로켓그로스 상품관리",
            desc1: "로켓그로스 상품DB를 수집을 클릭하면 신규 로켓그로스 상품DB가 수집됩니다.(선택수집도 가능함.)",
            desc2: "상품의 구매정보 및 매입가 정보를 입력하여 대량구매 및 매입이력 관리가 가능합니다. ",

            showlist: false,
            showspinner: false,
            showspinner1: false,
            barcode: '',
            SKUID: '',
            상품명: '',
            구매링크: '',
            제품분류: '',
            ProductList: {
                등록이미지: '',
                구매링크: '',
                옵션1_중국어: '',
                옵션2_중국어: '',
                판매구성수량: '',
                구매가능상태: '',
            },
            editQty:false,
            printskuenable: true,
            printkcenable: false,
            원산지: 'Made in China',

            // link:{ name: 'product.addproduct'},
            // linktext:"Add Product",
            //    selected: '',
            //    menuint: ["옵션1_중국어","옵션2_중국어","제품분류","판매구성수량","구매가능상태"],
            //    menuDefault: ["옵션1_중국어","옵션2_중국어","제품분류","판매구성수량","구매가능상태"],
            //    menuCost: ["발주가능상태","매입단가","공급가액","부가세액"],
            //    // menuSales: ["바코드","구매링크","판매가격","판매링크","판매재고","판매상품명"],
            productLists: [], //this.$store.state.productdb,
            productListsinit: [],

            바코드출력: '',
            SKU출력: '',
            출력수량: 1,
            바코드간격: 0,

            enabledetail: true,

            출력SKUID: '',
            출력바코드: '',
            출력상품명: '',
            한글표시사항: {
                '수입원/판매원': '',
                제조원: '',
                제조국: '',
                내용량: '',
                '원료명및성분명(재질)': '',
                상품유형: '',
                사용시주의사항: '',
                사용기준: '',
            },
            bcvalue: '',
            bctag: 'svg',
            bcoptions: {
                lineColor: '#ff7069',
                fontSize: 50,
                font: 'Courier',
                width: 3,
                height: 60,
                marginBottom: 60,
                format: 'MSI',
                background: '#ccffff'
            },
            total:0,
            아이템루저:0,
            뱃지없음:0,
            구매정보있음:0,
            구매정보없음:0,
            매입정보없음:0,
            editmode:false,
            showImport:true,
            currentIndex:'',
        }
    },
    components: {
        'barcode': VueBarcode,
        CDataTable
    },
    beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},	
    mounted() {
		this.messageEventHandler = async(event) => {
			if (event.data.msg && event.data.msg === 'growthDb') {
                if (event.data.res == 'success') {
                    this.showlist = false;
                    this.loadingtext = '수집 상품 저장 중 ...';
                    console.log(event.data)
                    var sizeInfo = event.data.sizeInfo;
                    var NoBadgeInfo = event.data.NoBadgeInfo;
                    var stockInfo = event.data.stockInfo;
                    var coupons = event.data.coupons;

                    var arr = event.data.growthdb;
                    var growthdb = [];
                    var vendorItemIds = this.$store.state.growthdb.map(e => e.vendorItemId)
                    for(var e of arr){
                        if(e.items){
                            for(var a of e.items){
                                if(a.skuId){
                                    if(sizeInfo.find(ele => parseInt(ele["옵션 ID"]) == parseInt(a.vendorItemId))){
                                        a.size = sizeInfo.find(ele => parseInt(ele["옵션 ID"]) == parseInt(a.vendorItemId))["상품 사이즈\n(필수)"];
                                    } else {
                                        a.size = '';
                                    }
                                    
                                    if(NoBadgeInfo.length > 0 && NoBadgeInfo.find(ele => ele.vendorItemId == a.vendorItemId)){
                                        a.badge = false
                                    } else {
                                        a.badge = true
                                    }
                                    if(stockInfo && stockInfo.length > 0 && stockInfo.find(ele => ele.vendorItemId == a.vendorItemId)){
                                        if(stockInfo.find(ele => ele.vendorItemId == a.vendorItemId).buyboxRole == 'WINNER'){
                                            a.winner = true;
                                        } else {
                                            a.winner = false;
                                        }
                                    } else {
                                        a.winner = true;
                                    }
                                    if(coupons && coupons.length > 0 && coupons.find(ele => ele.vendorItemId == a.vendorItemId)){
                                        a.coupon = coupons.find(ele => ele.vendorItemId == a.vendorItemId).couponAmount;
                                    } else {
                                        a.coupon = 0;
                                    }
                                    var 구매정보 = [{
                                        checked : false,
                                        showspinner : false,
                                        // SKUID : '',
                                        바코드 : a.barcode,
                                        상품명 : e.displayProductName + ', ' + a.itemName,
                                        상품명init : e.displayProductName + ', ' + a.itemName,
                                        구매링크 : undefined,
                                        구매링크init : undefined,
                                        옵션1_중국어 : undefined,
                                        옵션2_중국어 : undefined,
                                        옵션이미지: undefined,
                                        매칭상태 : '매칭필요',
                                        상품매칭 : {
                                            옵션1_중국어 : [],
                                            옵션2_중국어 : [],
                                        },
                                        구매재고 : '',
                                        구매요청수량 : '',
                                        판매구성수량 : 1,
                                        묶음구성여부 : false,
                                        묶음구성수량 : '',
                                        묶음대상바코드 : '',
                                        신고영문명 : '',
                                        신고가격 : '',
                                        HSCODE : '',
                                        진행현황 : '구매전',
                                        재고동봉 : true,
                                        등록이미지 : 'https://image1.coupangcdn.com/image/' + a.images[0].cdnPath,
                                        요청사항 : '요청등록',
                                        reqtype : '제트배송',
                                        sizeX : a.skuInfo.width,
                                        sizeY : a.skuInfo.length,
                                        sizeZ : a.skuInfo.heigth,
                                        weight : a.skuInfo.weight,
                                        요청내역 : {
                                            default : true,
                                            poarrange : false,

                                            한글표시사항 : true,
                                            barcode : true,
                                            sticker : false,
                                            sewing : false,
                                            stamp : false,
                                            tag : false,
                                            reqcontent : '',
                                            opp : false,
                                            LDPE : false,
                                            airpacking : false,
                                            exportType : 'box',
                                            destination : 'coupang',
                                            masking : false,
                                            auto : true,
                                        },
                                        한글표시사항 : {
                                            제품명 : e.displayProductName + ', ' + a.itemName,
                                            '수입원/판매원' : '',
                                            제조원 : '',
                                            제조국 : '',
                                            내용량 : '',
                                            '원료명및성분명(재질)' : '',
                                            상품유형 : '',
                                            사용시주의사항 : '',
                                            사용기준 : '',
                                            custom : '',
                                        },
                                        polists : [],
                                        selectpo : '', 
                                    }];
                                    if(vendorItemIds.includes(a.vendorItemId)){
                                        var obj = {
                                            companyid : this.$store.state.user.companyid,
                                            suppliercode : this.$store.state.user.suppliercode,
                                            attributes : a.attributes,
                                            autoPricingInfo : a.autoPricingInfo,
                                            barcode : a.barcode,
                                            externalVendorSku : a.externalVendorSku,
                                            optionImage : a.images[0].cdnPath,
                                            optionImageUrl : 'https://image1.coupangcdn.com/image/' + a.images[0].cdnPath,
                                            productId : e.productId,
                                            itemId : a.itemId,
                                            vendorItemId : a.vendorItemId,
                                            itemName : a.itemName,
                                            displayProductName : e.displayProductName,
                                            productName : e.displayProductName + ', ' + a.itemName,
                                            statusName : e.statusName,
                                            skuId : a.skuId,
                                            salePrice : a.salePrice,
                                            sizeInfo : {
                                                width : a.skuInfo.width,
                                                length : a.skuInfo.length,
                                                height : a.skuInfo.height,
                                                weight : a.skuInfo.weight,
                                                netWeight : a.skuInfo.netWeight,
                                            },
                                            size : a.size,
                                            winner : a.winner,
                                            badge : a.badge,
                                            commisionRate : a.수수료.판매수수료,
                                            commision : parseInt(a.salePrice*a.수수료.판매수수료*0.01),
                                            warehouseFee : parseInt(a.수수료.입출고비),
                                            fullfillmentFee : parseInt(a.수수료.배송비),
                                            coupon : a.coupon,
                                            info : e,

                                            상품명 : e.displayProductName + ', ' + a.itemName,
                                            바코드 : a.barcode,
                                            노출ID : e.productId,
                                            옵션ID : a.vendorItemId,
                                            승인상태 : e.statusName,
                                            옵션이미지 : 'https://image1.coupangcdn.com/image/' + a.images[0].cdnPath,

                                            구매정보 : this.$store.state.growthdb.find(ele => ele.옵션ID == a.vendorItemId)?.구매정보 || 구매정보,
                                            구매정보여부 : this.$store.state.growthdb.find(ele => ele.옵션ID == a.vendorItemId)?.구매정보여부 || false,
                                            판매링크 : 'https://www.coupang.com/vp/products/' + e.productId + '?vendorItemId=' + a.vendorItemId,
                                            아이템위너 : a.winner,
                                            뱃지 : a.badge,

                                            사이즈 : a.size,
                                            사이즈정보 : {
                                                width : a.skuInfo.width,
                                                length : a.skuInfo.length,
                                                height : a.skuInfo.height,
                                                weight : a.skuInfo.weight,
                                                netWeight : a.skuInfo.netWeight,
                                            },
                                            판매가 : a.salePrice,

                                            풀필먼트수수료 : parseInt(a.수수료.배송비),
                                            입출고수수료 : parseInt(a.수수료.입출고비),
                                            판매수수료 : parseInt(a.salePrice*a.수수료.판매수수료*0.01),
                                            판매수수료율 : a.수수료.판매수수료,
                                            수수료 : ((parseInt(a.수수료.배송비) + parseInt(a.수수료.입출고비) + a.salePrice*a.수수료.판매수수료*0.01)*1.1).toFixed(0),

                                            할인쿠폰 : a.coupon,
                                            매입정보 : this.$store.state.growthdb.find(ele => ele.옵션ID == a.vendorItemId)?.매입정보 || [],
                                            매입가 : this.$store.state.growthdb.find(ele => ele.옵션ID == a.vendorItemId)?.매입가 || "",
                                            마진 : this.$store.state.growthdb.find(ele => ele.옵션ID == a.vendorItemId)?.마진 || "",
                                            마진율 : this.$store.state.growthdb.find(ele => ele.옵션ID == a.vendorItemId)?.마진율 || "",
                                            ROI : this.$store.state.growthdb.find(ele => ele.옵션ID == a.vendorItemId)?.ROI || "",
                                            ROAS : this.$store.state.growthdb.find(ele => ele.옵션ID == a.vendorItemId)?.ROAS || "",
                                        }

                                        this.changeCost(obj);
                                        this.changeMargin(obj);
                                    } else {
                                        obj = {
                                            companyid : this.$store.state.user.companyid,
                                            suppliercode : this.$store.state.user.suppliercode,
                                            attributes : a.attributes,
                                            autoPricingInfo : a.autoPricingInfo,
                                            barcode : a.barcode,
                                            externalVendorSku : a.externalVendorSku,
                                            optionImage : a.images[0].cdnPath,
                                            optionImageUrl : 'https://image1.coupangcdn.com/image/' + a.images[0].cdnPath,
                                            productId : e.productId,
                                            itemId : a.itemId,
                                            vendorItemId : a.vendorItemId,
                                            itemName : a.itemName,
                                            displayProductName : e.displayProductName,
                                            productName : e.displayProductName + ', ' + a.itemName,
                                            statusName : e.statusName,
                                            skuId : a.skuId,
                                            salePrice : a.salePrice,
                                            sizeInfo : {
                                                width : a.skuInfo.width,
                                                length : a.skuInfo.length,
                                                height : a.skuInfo.height,
                                                weight : a.skuInfo.weight,
                                                netWeight : a.skuInfo.netWeight,
                                            },
                                            size : a.size,
                                            winner : a.winner,
                                            badge : a.badge,
                                            commisionRate : a.수수료.판매수수료,
                                            commision : parseInt(a.salePrice*a.수수료.판매수수료*0.01),
                                            warehouseFee : parseInt(a.수수료.입출고비),
                                            fullfillmentFee : parseInt(a.수수료.배송비),
                                            coupon : a.coupon,
                                            info : e,

                                            purchaseInfo : 구매정보,
                                            purchaseInfoReg : false,
                                            show : true,


                                            상품명 : e.displayProductName + ', ' + a.itemName,
                                            바코드 : a.barcode,
                                            노출ID : e.productId,
                                            옵션ID : a.vendorItemId,
                                            승인상태 : e.statusName,
                                            옵션이미지 : 'https://image1.coupangcdn.com/image/' + a.images[0].cdnPath,

                                            구매정보 : 구매정보,
                                            구매정보여부 : false,
                                            판매링크 : 'https://www.coupang.com/vp/products/' + e.productId + '?vendorItemId=' + a.vendorItemId,
                                            아이템위너 : a.winner,
                                            뱃지 : a.badge,

                                            사이즈 : a.size,
                                            사이즈정보 : {
                                                width : a.skuInfo.width,
                                                length : a.skuInfo.length,
                                                height : a.skuInfo.height,
                                                weight : a.skuInfo.weight,
                                                netWeight : a.skuInfo.netWeight,
                                            },
                                            판매가 : a.salePrice,

                                            풀필먼트수수료 : parseInt(a.수수료.배송비),
                                            입출고수수료 : parseInt(a.수수료.입출고비),
                                            판매수수료 : parseInt(a.salePrice*a.수수료.판매수수료*0.01),
                                            판매수수료율 : a.수수료.판매수수료,
                                            수수료 : ((parseInt(a.수수료.배송비) + parseInt(a.수수료.입출고비) + a.salePrice*a.수수료.판매수수료*0.01)*1.1).toFixed(0),

                                            할인쿠폰 : a.coupon,
                                            매입정보 : [],
                                            매입가 : "",
                                            마진 : "",
                                            마진율 : "",
                                            ROI : "",
                                            ROAS : ""
                                        }
                                    }
                                    growthdb.push(this.deepClone(obj))
                                }
                            }
                        }
                    }
                    try{
                        var result = await axios.post('/api/updateGrowthDB',{growthdb:growthdb});
                        if(result.data.res == 'success'){
                            this.search();
                        } else {
                            alert(result.data.alert)
                        }
                    }catch(err){
                        alert(err)
                    }  
                } else {
                    alert(event.data.res)
                }
                this.showlist = true;
                this.showspinner = false;
                this.showspinner1 = false;
            }
            if (event.data.res && event.data.res === 'growthdbratio'){
                if(event.data.ratio == 0){
                    this.loadingtext = event.data.text    
                } else {
                    this.loadingtext = event.data.text + event.data.ratio.toFixed(0) + '%'
                }
            }
		}
		window.addEventListener('message', this.messageEventHandler);
        this.$nextTick(function () {
            this.search()
        })
    },
    computed: {
        
        enablekr() {
            if (this.enabledetail) {
                return 'font-size:80%;color:black;line-height:110%;display:block;margin-bottom:0px;'
            } else {
                return 'font-size:80%;color:black;line-height:110%;display:none'
            }
        },
        disablekr() {
            if (this.enabledetail) {
                return 'font-size:80%;color:black;line-height:110%;display:none'
            } else {
                return 'font-size:80%;color:black;line-height:110%;display:block'
            }
        },
        printsku() {
            if (this.printskuenable) {
                return 'font-size:90%;color:black;'
            } else {
                return 'display:none;font-size:90%;color:black;'
            }
        },
        printkc() {
            if (this.printkcenable) {
                return 'font-size:90%;color:black;margin-left:20px;margin-top:10px'
            } else {
                return 'display:none;font-size:90%;color:black;'
            }
        }
    },
    methods: {
        editQtyEnter($event){
            if($event.keyCode == 13){
                this.editQty = false;
            }
        },
        cancelPurchaseInfo($event,item){
            $event.preventDefault()
            item.구매정보여부 = this.productListsinit.find(e => e._id == item._id).구매정보여부;
            item.구매정보 = this.productListsinit.find(e => e._id == item._id).구매정보;
            this.구매정보없음 = this.productLists.filter(e => !e.구매정보여부).length;
            this.구매정보있음 = this.productLists.filter(e => e.구매정보여부).length;
            this.$nextTick(() => {
                this.$bvModal.hide('modalpurchase' + item._id);
            })
        },
        async savePurchaseInfo($event,item){
            $event.preventDefault()
            this.showlist = false;
            var 현재구매정보여부 = this.productListsinit.find(e => e._id == item._id).구매정보여부;
            var 현재구매정보 = this.productListsinit.find(e => e._id == item._id).구매정보;

            if(!item.구매정보 || item.구매정보.length == 0 || item.구매정보.find(e => e.매칭상태 !== '매칭완료' || e.요청사항 !== '등록완료')){
                var 구매정보여부 = false
            } else {
                구매정보여부 = true;
            }

            this.productListsinit.find(e => e._id == item._id).구매정보여부 = 구매정보여부;
            this.productListsinit.find(e => e._id == item._id).구매정보 = item.구매정보;
            item.구매정보여부 = 구매정보여부;

            console.log(item.구매정보)
            console.log(item)
            console.log(this.productListsinit.filter(e => e.노출ID == item.노출ID && e.구매정보.find(f => f.요청사항 !== "등록완료")))
            console.log(this.productListsinit.filter(e => e.구매정보.find(f => f.요청사항 !== "등록완료")))

            if(구매정보여부 && this.productListsinit.filter(e => e.노출ID == item.노출ID && e.구매정보.find(f => f.요청사항 !== "등록완료")).length > 0){
                if(confirm('현재 구매정보를 동일한 노출ID에 모두 적용하시겠습니까? 적용대상 : ' + this.productListsinit.filter(e => e.노출ID == item.노출ID).length + '개')){
                    var cnt = 0;
                    for(var e of this.productListsinit.filter(e => e.노출ID == item.노출ID)){
                        if(e.바코드 !== item.바코드){
                            console.log(e)
                            e.구매정보여부 = false;
                            e.구매정보 = [];
                            for(var i=0;i<item.구매정보.length;i++){
                                e.구매정보[i] = this.deepClone(item.구매정보[i])
                            }
                            for(i=0;i<e.구매정보.length;i++){
                                var f = e.구매정보[i];
                                console.log(e.구매정보)
                                f.바코드 = e.바코드;
                                f.상품명 = e.상품명;
                                f.상품명init = e.상품명;
                                f.등록이미지 = e.옵션이미지;
                                f.한글표시사항.제품명 = e.상품명;
                                f.옵션이미지 = undefined;
                                
                                f.옵션1_중국어 = f.상품매칭.옵션1_중국어.length > 0 ? undefined : "";
                                f.옵션2_중국어 = f.상품매칭.옵션2_중국어.length > 0 ? undefined : "";

                                if(f.옵션1_중국어 == undefined || f.옵션2_중국어 == undefined){
                                    f.매칭상태 = "매칭필요";
                                } else {
                                    f.매칭상태 = "매칭완료";
                                }
                            }
                        }
                        var res = await this.saveImportInfo(e,false);
                        if(res){
                            cnt++
                        }
                        this.구매정보없음 = this.productLists.filter(e => !e.구매정보여부).length;
                        this.구매정보있음 = this.productLists.filter(e => e.구매정보여부).length;
                    }
                    alert(cnt + '개 상품 적용완료')
                    this.$store.commit('growthdbupdate', this.productListsinit);
                }
            } else {
                res = await this.saveImportInfo(item,true);
                if(!res){
                    this.productListsinit.find(e => e._id == item._id).구매정보여부 = 현재구매정보여부;
                    this.productListsinit.find(e => e._id == item._id).구매정보 = 현재구매정보;
                    item.구매정보여부 = 현재구매정보여부;
                    item.구매정보 = 현재구매정보;
                } else {
                    this.$store.commit('growthdbupdate', this.productListsinit);
                }
                this.구매정보없음 = this.productLists.filter(e => !e.구매정보여부).length;
                this.구매정보있음 = this.productLists.filter(e => e.구매정보여부).length;
            }
            this.searchResult(this.$store.state.growthdb);
            
            this.$nextTick(() => {
                this.$bvModal.hide('modalpurchase' + item._id);
                this.showlist = true;
            })
            
        },
        classPurchaseInfo(item){
            if(item.구매정보여부){
                return 'success'
            } else {
                return 'secondary'
            }
        },
        async copyReq(lists){
            console.log(lists[0])
            var newObj = this.deepClone(lists[0]);
            if(newObj.상품매칭.옵션1_중국어.length > 0){
                newObj.옵션1_중국어 = undefined;
            } else {
                newObj.옵션1_중국어 = '';
            }
            if(newObj.상품매칭.옵션2_중국어.length > 0){
                newObj.옵션2_중국어 = undefined;
            } else {
                newObj.옵션2_중국어 = '';
            }
            newObj.상품명init = newObj.상품명
            newObj.옵션이미지 = undefined;
            newObj.매칭상태 = '매칭필요';
            if(!newObj.요청내역.destination){
                newObj.요청내역.destination = 'coupang'
            }
            lists.forEach(e => {
                e.묶음구성여부 = true;
                e.묶음구성수량 = lists.length+1;
            })
            lists.push(newObj);
        },
        addProductName(list){
            list.상품명 = list.상품명init;
        },
        changeBarcode(item){
            if(!item.요청내역.barcode){
                alert('바코드라벨 미부착 시 반드시 원산지가 표시되어 있어야 합니다.')
                item.요청내역.sticker = true;
            } else {
                item.요청내역.sticker = false;
            }
        },
        confirmBarcode(list){
            if(list.바코드.includes('no') || list.바코드.includes('NO') || list.바코드.includes('No') || list.바코드.includes('노')){
                if(list.묶음구성여부){
                    alert('바코드 정보가 없는 경우 묶음구성 진행이 어렵습니다. 바코드를 먼저 생성해 주세요')
                    return
                }
            }
        },
        async importHideProducts(){
            this.showHideProducts = false;
            this.showspinner2 = true;
            if(this.searchProducts.length == 0){
                try{
                    var res = await axios.post('/api/getGrowthDB',{show:false});
                    this.searchProducts = res.data.growthdb;
                    console.log(this.searchProducts)
                    // this.$store.commit('growthdbupdate', result.data.growthdb);
                    // this.searchResult(this.$store.state.growthdb);
                    this.showHideProducts = true;
                    this.showspinner2 = false;
                }catch(e){
                    alert('불러오기 실패')
                    console.log(e)
                }                
            } else {
                // alert('수집된 상품DB가 없습니다. 로켓그로스 상품DB 수집을 진행해 주세요.(쿠팡wing 로그인 필수)')
                // this.searchResult(this.$store.state.growthdb);
                this.showHideProducts = true;
                this.showspinner2 = false;
            }
        },
        async saveImport($event,item){
            $event.preventDefault()
            // this.showlist = false;
            var currentImportInfo = this.productListsinit.find(e => e._id == item._id).매입정보;
            var 현재매입가 = this.productListsinit.find(e => e._id == item._id).매입가;
            var 현재마진 = this.productListsinit.find(e => e._id == item._id).마진;
            var 현재마진율 = this.productListsinit.find(e => e._id == item._id).마진율;
            var 현재ROI = this.productListsinit.find(e => e._id == item._id).ROI;
            var 현재ROAS = this.productListsinit.find(e => e._id == item._id).ROAS;

            this.changeCost(item);
            this.changeMargin(item);
            this.productListsinit.find(e => e._id == item._id).매입정보 = item.매입정보;
            this.productListsinit.find(e => e._id == item._id).매입가 = item.매입가;
            this.productListsinit.find(e => e._id == item._id).마진 = item.마진;
            this.productListsinit.find(e => e._id == item._id).마진율 = item.마진율;
            this.productListsinit.find(e => e._id == item._id).ROI = item.ROI;
            this.productListsinit.find(e => e._id == item._id).ROAS = item.ROAS;

            var res = await this.saveImportInfo(item,true);
            if(!res){
                this.productListsinit.find(e => e._id == item._id).매입정보 = currentImportInfo;
                this.productListsinit.find(e => e._id == item._id).매입가 = 현재매입가;
                this.productListsinit.find(e => e._id == item._id).마진 = 현재마진;
                this.productListsinit.find(e => e._id == item._id).마진율 = 현재마진율;
                this.productListsinit.find(e => e._id == item._id).ROI = 현재ROI;
                this.productListsinit.find(e => e._id == item._id).ROAS = 현재ROAS;

                item.매입정보 = currentImportInfo;
                item.매입가 = 현재매입가;
                item.마진 = 현재마진;
                item.마진율 = 현재마진율;
                item.ROI = 현재ROI;
                item.ROAS = 현재ROAS;
            } else {
                this.$store.commit('growthdbupdate', this.productListsinit);
            }
            this.매입정보없음 = this.productLists.filter(e => !e.매입가).length;
            this.$nextTick(()=>{
                this.$bvModal.hide('modalImport' + item._id);
            })
            
            // setTimeout(()=>{
            //     this.showlist = true;
            // },10)
        },
        async saveImportInfo(data,bool){
            try{
                var res = await axios.post('/api/growthdb/saveDBInfo',data);
                if(res.data.res !== 'success'){
                    alert('저장실패(901) : ' + res.data.alert)
                    return false
                } else {
                    if(bool){
                        alert('저장완료')
                    }
                    return true
                }
            }catch(e){
                alert('저장실패(905) : ' + e)
                return false
            }
        },
        cancelImport(item){
            item.매입정보 = this.productListsinit.find(e => e._id == item._id).매입정보;
            this.changeCost(item);
            this.changeMargin(item);
            this.매입정보없음 = this.productLists.filter(e => !e.매입가).length;
            this.$nextTick(()=>{
                this.$bvModal.hide('modalImport' + item._id);
            })
        },
        changeCost(item){
            if(item.매입정보 && item.매입정보.length > 0){
                item.매입가 = parseInt(item.매입정보[item.매입정보.length-1].매입가);
            } else {
                item.매입가 = "";
            }
        },
        changeMargin(item){
            console.log(item)
            item.판매수수료 = (item.판매가-item.할인쿠폰)*item.판매수수료율*0.01;
            item.수수료 = (item.판매수수료+item.풀필먼트수수료+item.입출고수수료)*1.1;
            if(item.매입가){
                item.마진 = parseInt((item.판매가-item.할인쿠폰-item.수수료-item.매입가)*10/11)
                item.마진율 = Math.round(item.마진/(item.판매가-item.할인쿠폰)*100)/100;
                item.ROI = Math.round(item.마진/item.매입가*100)/100;
                item.ROAS = Math.round((item.판매가-item.할인쿠폰)/item.마진*110)/100;
            }
        },
        getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);
			return year + "-" + month + "-" + day;
		},
        addImportInfo(productList){
            this.showImport = false;
            if(productList.매입정보){
                productList.매입정보.push({
                    date : this.getTodaywith(),
                    매입가 : 0,
                })
            } else {
                productList.매입정보 = [{
                    date : this.getTodaywith(),
                    매입가 : 0,
                }]
            }
            setTimeout(()=>{
                this.showImport = true;
                this.editmode = true;
            },1)
        },
        changeImportInfo(event){
            if(event.keyCode == 13){
				this.editmode = false;
			}
        },
        editImport(){
            if(this.editmode == false){
                this.editmode = true;
            } else {
                this.editmode = false;
            }
        },
        deleteImport(productList,index){
            productList.매입정보.splice(index, 1);
        },
        async calMargin(productList){
            this.showlist = false;
            if(productList.매입가 && productList.매입가 > 0){
                var 부가세 = productList.판매가/11 - productList.수수료/11 - productList.매입가/11
                productList.마진 = parseInt(productList.판매가 - productList.매입가 - productList.수수료 - 부가세);
                productList.마진율 = Math.round((productList.판매가 - productList.매입가 - productList.수수료 - 부가세)/productList.판매가*100*10)/10
                productList.ROI = Math.round((productList.판매가 - productList.매입가 - productList.수수료 - 부가세)/productList.매입가*100*10)/10
            } else {
                productList.마진 = '';
                productList.마진율 = '';
                productList.ROI = '';
            }
            setTimeout(()=>{
                this.showlist = true;
            },1)
        },
        async delManageItems(){
            if(this.tableSelectedItem.length == 0){
                alert('관리삭제할 항목을 선택해 주세요')
                return
            }
            if(confirm(this.tableSelectedItem.length + '개의 항목을 관리삭제(숨김처리)하시겠습니까?')){
                try{
                    var input = this.tableSelectedItem.map(e => e._id);
                    var res = await axios.post('/api/growthdb/hideProduct',input);
                    if(res.data.res == 'success'){
                        this.productLists = this.productLists.filter(e => !input.includes(e._id));
                        this.productListsinit = this.productListsinit.filter(e => !input.includes(e._id));
                        console.log(this.searchProducts)
                        this.searchProducts = this.searchProducts.concat(this.tableSelectedItem);
                        this.$store.commit('growthdbupdate', this.productListsinit);
                    } else {
                        alert(res.data.alert)
                    }
                }catch(e){
                    alert('err823' + e)
                }
            }
        },
        changePackage(list){
            if(this.packageMethod == 'opp'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'LDPE'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'noPackage'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'auto'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = true;
                return
            }
        },
        addSearchProduct(list,index){
            console.log(list)
            this.searchProducts.splice(index, 1);
            var f = list;
            if(!f.구매정보 || f.구매정보여부.length == 0){
                f.구매정보 = [{
                    checked : false,
                    showspinner : false,
                    // SKUID : '',
                    바코드 : f.barcode,
                    상품명 : f.productName,
                    상품명init : f.productName,
                    구매링크 : undefined,
                    구매링크init : undefined,
                    옵션1_중국어 : undefined,
                    옵션2_중국어 : undefined,
                    옵션이미지: undefined,
                    매칭상태 : '매칭필요',
                    상품매칭 : {
                        옵션1_중국어 : [],
                        옵션2_중국어 : [],
                    },
                    구매재고 : '',
                    구매요청수량 : '',
                    판매구성수량 : 1,
                    묶음구성여부 : false,
                    묶음구성수량 : '',
                    묶음대상바코드 : '',
                    신고영문명 : '',
                    신고가격 : '',
                    HSCODE : '',
                    진행현황 : '구매전',
                    재고동봉 : true,
                    등록이미지 : f.optionImageUrl,
                    요청사항 : '요청등록',
                    reqtype : '제트배송',
                    sizeX : f.sizeInfo ? f.sizeInfo.width : 0,
                    sizeY : f.sizeInfo ? f.sizeInfo.length : 0,
                    sizeZ : f.sizeInfo ? f.sizeInfo.heigth : 0,
                    weight : f.sizeInfo ? f.sizeInfo.weight : 0,
                    요청내역 : {
                        default : true,
                        poarrange : false,
                        한글표시사항 : true,
                        barcode : true,
                        sticker : false,
                        stamp : false,
                        tag : false,
                        reqcontent : '',
                        opp : false,
                        LDPE : false,
                        airpacking : false,
                        exportType : 'box',

                        masking : false,
                        destination : 'coupang',
                        auto : true,
                    },
                    한글표시사항 : {
                        제품명 : f.productName,
                        '수입원/판매원' : '',
                        제조원 : '',
                        제조국 : '',
                        내용량 : '',
                        '원료명및성분명(재질)' : '',
                        상품유형 : '',
                        사용시주의사항 : '',
                        사용기준 : '',
                        custom : '',
                    },
                    polists : [],
                    selectpo : '', 
                }];
            }
            var obj = {
                _id : f._id,
                상품명 : f.상품명 ? f.상품명 : f.productName,
                바코드 : f.바코드 ? f.바코드 : f.barcode,
                노출ID : f.노출ID ? f.노출ID : f.productId,
                옵션ID : f.옵션ID ? f.옵션ID : f.vendorItemId,
                승인상태 : f.승인상태 ? f.승인상태 : f.statusName,
                옵션이미지 : f.옵션이미지 ? f.옵션이미지 : f.optionImageUrl,

                구매정보 : f.구매정보 ? f.구매정보 : f.purchaseInfo,
                구매정보여부 : f.구매정보여부 ? f.구매정보여부 : f.purchaseInfoReg,
                판매링크 : f.판매링크 ? f.판매링크 : 'https://www.coupang.com/vp/products/' + f.productId + '?vendorItemId=' + f.vendorItemId,
                아이템위너 : f.아이템위너 ? f.아이템위너 : f.winner,
                뱃지 : f.뱃지 ? f.뱃지 : f.badge,

                사이즈 : f.사이즈 ? f.사이즈 : f.size,
                사이즈정보 : f.사이즈정보 ? f.사이즈정보 : f.sizeInfo,
                판매가 : f.판매가 ? f.판매가 : f.salePrice,

                풀필먼트수수료 : f.풀필먼트수수료 ? f.풀필먼트수수료 : f.fullfillmentFee,
                입출고수수료 : f.입출고수수료 ? f.입출고수수료 : f.warehouseFee,
                판매수수료 : f.판매수수료 ? f.판매수수료 : f.commision,
                판매수수료율 : f.판매수수료율 ? f.판매수수료율 : f.commisionRate,
                수수료 : f.수수료 ? f.수수료 : ((parseInt(f.fullfillmentFee) + parseInt(f.warehouseFee) + f.commision)*1.1).toFixed(0),

                매입정보 : f.매입정보 ? f.매입정보 : f.importInfo,
                매입가 : f.매입가 ? f.매입가 : f.매입가,
                마진 : f.마진 ? f.마진 : f.마진,
                마진율 : f.마진율 ? f.마진율 : f.마진율,
                ROI : f.ROI ? f.ROI : f.ROI,
                ROAS : f.ROAS ? f.ROAS : f.ROAS
            }

            this.addManage(obj);
            //hideProduct show를 true로 변경하는 axios 추가


            
            this.total = this.productLists.length;
            this.뱃지없음 = this.productLists.filter(e => e.뱃지 == 'N').length;
            this.아이템루저 = this.productLists.filter(e => e.아이템위너 == 'N').length;
            this.구매정보없음 = this.productLists.filter(e => !e.구매정보여부).length;
            this.구매정보있음 = this.productLists.filter(e => e.구매정보여부).length;
            this.showlist = true;
            this.showspinner = false;
            this.showspinner1 = false;
            // this.productList = list;
        },
        delSearchProduct(list){
            delete list.add;
        },
        async addManage(item){
            var vendorItemId = item.옵션ID;
            try{
                var res = await axios.post('/api/growthdb/showProduct',[vendorItemId])
                if(res.data.res == 'success'){
                    this.productLists.push(item)
                    this.productListsinit.push(this.deepClone(item))
                    this.$store.commit('growthdbupdate', this.productListsinit);
                } else {
                    alert(res.data.alert)
                }
            }catch(e){
                console.log('err844' + e);
            }
        },
        delAddProduct(index){
            this.addProducts.splice(index, 1);
        },
        confirmlist(list){
            if(!list.바코드){
                if(!confirm('바코드가 없는 경우 로켓그로스 출고가 불가능합니다.')){
                    return false
                }
            }
            if(!list.신고영문명){
                alert("요청내역 중 신고영문명을 작성해주세요. ex)여성신발 -> women's shoes ")
                return false
            }
            // if(!list.신고가격){
            //     alert("요청내역 중 신고가격을 USD로 환산하여 작성해주세요. ex) 2달러 -> 2 ")
            //     return false
            // }            
            if(!list.HSCODE){
                alert("요청내역 중 HS-CODE를 작성해주세요. ex) 6303.92-0000 ")
                return false
            }
            if(list.HSCODE.length !== 12){
                alert("HS-CODE를 Full name으로 작성해주세요. ex) 6303.92-0000 ")
                return false
            }
            if(list.구매요청수량 == 0 || !list.구매요청수량){
                alert("구매요청수량을 작성해 주세요.")
                return false
            }
            if(list.판매구성수량 == 0 || !list.판매구성수량){
                alert("판매구성수량을 작성해 주세요.")
                return false
            }  
            list.한글표시사항.제품명 = list.상품명;
            if(Object.keys(list.한글표시사항).find(e => list.한글표시사항[e].length == 0 && e !== '제품명' && e !== 'custom')){
                console.log(list.한글표시사항)
                alert('한글표시사항을 모두 작성해 주세요')
                return false
            }
            return true      
        },
        async savereq($event,list,index){
            $event.preventDefault();
            var foo = this.confirmlist(list)
            if(!foo){
                return
            }
            list.요청사항 = '등록완료';
            console.log(list)
            
            this.$nextTick(() => {
                this.$bvModal.hide('modalreq' + index);
                console.log('modalreq' + index)
            })
            
            
        },
        // regrequest(list,index){
        //     if(!list.상품명){
        //         alert('상품명을 먼저 입력해 주세요')
        //         return
        //     }
        //     this.$bvModal.show('modalreq' + index);
        // },
        async addlists(item){
            var obj = {
                checked : false,
                showspinner : false,
                // SKUID : '',
                바코드 : item.바코드,
                상품명 : item.상품명,
                상품명init : item.상품명,
                구매링크 : undefined,
                구매링크init : undefined,
                옵션1_중국어 : undefined,
                옵션2_중국어 : undefined,
                옵션이미지: undefined,
                매칭상태 : '매칭필요',
                상품매칭 : {
                    옵션1_중국어 : [],
                    옵션2_중국어 : [],
                },
                구매재고 : '',
                구매요청수량 : '',
                판매구성수량 : 1,
                묶음구성여부 : false,
                묶음구성수량 : '',
                묶음대상바코드 : '',
                신고영문명 : '',
                신고가격 : '',
                HSCODE : '',
                진행현황 : '구매전',
                재고동봉 : true,
                등록이미지 : item.옵션이미지,
                요청사항 : '요청등록',
                reqtype : '제트배송',
                sizeX : item.sizeInfo ? item.sizeInfo.width : "",
                sizeY : item.sizeInfo ? item.sizeInfo.length : "",
                sizeZ : item.sizeInfo ? item.sizeInfo.heigth : "",
                weight : item.sizeInfo ? item.sizeInfo.weight : "",
                요청내역 : {
                    default : true,
                    poarrange : false,

                    한글표시사항 : true,
                    barcode : true,
                    sticker : false,
                    sewing : false,
                    stamp : false,
                    tag : false,
                    reqcontent : '',
                    opp : false,
                    LDPE : false,
                    airpacking : false,
                    exportType : 'box',
                    destination : 'coupang',
                    masking : false,
                    auto : true,
                },
                한글표시사항 : {
                    제품명 : item.상품명,
                    '수입원/판매원' : '',
                    제조원 : '',
                    제조국 : '',
                    내용량 : '',
                    '원료명및성분명(재질)' : '',
                    상품유형 : '',
                    사용시주의사항 : '',
                    사용기준 : '',
                },
                polists : [],
                selectpo : '',               
            }
            item.구매정보.push(obj)
        },
        toggleCollapse(item) {
            if(this.isCollapseOpen){
                this.purchaseItem.purchaseInfoLists = [];
            } else {
                this.purchaseItem = this.deepClone(item);
            }
            this.isCollapseOpen = !this.isCollapseOpen;
        },
        deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
        collectGrowthDb() {
            var updatedb = this.tableSelectedItem;
            window.postMessage({ greeting: "collectGrowthDb", companyid: this.$store.state.user.companyid, suppliercode: this.$store.state.user.suppliercode, detailSize:true, growthdb:this.$store.state.user.growthdb, updatedb:updatedb}, "*",)
            this.showspinner1 = true;
            this.showlist = false;
        },
        barcodegap(index, total) {
            if (index < total) {
                return 'border:0px solid silver;width:300px;text-align: center;margin-bottom:' + this.바코드간격 + 'px'
            } else {
                return 'border:0px solid silver;width:300px;text-align: center;'
            }
        },
        printbarcoderef() {
            if (this.바코드출력 != '') {
                this.printbarcode(this.바코드출력)
                // this.바코드출력 = ''
            } else {
                if (this.SKU출력 != '') {
                    var barcode = this.$store.state.productdb.find(e => e["SKU ID"] == this.SKU출력).바코드
                    this.printbarcode(barcode);
                    // this.SKU출력 = ''
                }
            }
        },
        printbarcodewith(event) {
            if (event.keyCode === 13) {
                this.printbarcode(event.target.value)
                // this.바코드출력 = ''
            }
        },
        printSKUwith(event) {
            if (event.keyCode === 13) {
                var barcode = this.$store.state.productdb.find(e => e["SKU ID"] == event.target.value).바코드
                this.printbarcode(barcode);
                // this.SKU출력 = ''
            }
        },
        printbarcode(barcode) {
            if (this.$store.state.productdb.length == 0 || !this.$store.state.productdb.find(e => e.바코드 == barcode)) {
                // axios.post('/api/logisaddimport/importbarcodeinfo',{barcode:barcode})
                // .then((res) => {
                // 	this.출력SKUID = res.data.res[0]["SKU ID"]
                // 	this.출력바코드 = barcode;
                // 	this.출력상품명 = res.data.res[0]['상품명'];
                // 	this.한글표시사항 = {
                // 		'수입원/판매원':res.data.res[0]['수입원/판매원'],
                // 		제조원:res.data.res[0].제조원,
                // 		제조국:res.data.res[0].제조국,
                // 		내용량:res.data.res[0].내용량,
                // 		'원료명및성분명(재질)':res.data.res[0]['원료명및성분명(재질)'],
                // 		상품유형:res.data.res[0].상품유형,
                // 		사용시주의사항:res.data.res[0].사용시주의사항,
                // 		사용기준:res.data.res[0].사용기준,
                // 	}
                // 	setTimeout(() => {
                // 		this.print('printMe8');
                // 	},10)
                // })
                // .catch((e) => {alert("importbarcode 실패(1465)");console.log(e);})

                alert('상품DB에 존재하지 않은 바코드입니다. 상품DB를 업데이트한 후 다시 시도해 주세요')
                return
            } else {
                var ob = this.$store.state.productdb.find(e => e.바코드 == barcode)
                this.출력SKUID = ob['SKU ID'];
                this.출력바코드 = barcode;
                this.출력상품명 = ob['상품명'];
                this.한글표시사항 = {
                    '수입원/판매원': ob['수입원/판매원'],
                    제조원: ob.제조원,
                    제조국: ob.제조국,
                    내용량: ob.내용량,
                    '원료명및성분명(재질)': ob['원료명및성분명(재질)'],
                    상품유형: ob.상품유형,
                    사용시주의사항: ob.사용시주의사항,
                    사용기준: ob.사용기준,
                }
                setTimeout(() => {
                    this.print('printMe8');
                }, 10)
            }

        },
        async print(id) {
            // Pass the element id here
            var options = {
                name: '_blank',
                autoClose: true,
            }
            await this.$htmlToPaper(id, options);
        },
        excelDownFunc() {
            var dataWS = XLSX.utils.json_to_sheet(this.productLists);

            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'RocketGrowthDB');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, 'RocketGrowthDB.xlsx');
        },
        edit(ProductList) {
            this.$store.state.productdb.some(e => {
                if (e["SKU ID"] == ProductList["SKU ID"]) {
                    e.등록이미지 = ProductList.등록이미지;
                    e.구매링크 = ProductList.구매링크;
                    e.옵션1_중국어 = ProductList.옵션1_중국어;
                    e.옵션2_중국어 = ProductList.옵션2_중국어;
                    e.판매구성수량 = ProductList.판매구성수량;
                    e.구매가능상태 = ProductList.구매가능상태;
                    axios.post('/api/addproduct/editproductdb', ProductList)
                        .then((res) => { console.log(res.data.요청결과) })
                        .catch(console.log)
                    return (e["SKU ID"] == ProductList["SKU ID"])
                }
            })
        },
        MenuUpdate() {
            console.log(this.selected)
            if (this.selected == "radio1") {
                this.menuint = this.menuDefault;
            } else {
                if (this.selected == "radio2") {
                    this.menuint = this.menuCost;
                }
                //  else {
                //     this.menuint = this.menuSales;
                // }
            }
        },
        modalID(index) {
            return 'modal' + index
        },
        modaledit(index) {
            return 'modaledit' + index
        },
        searchpd(event) {
            if (event.keyCode === 13) {
                this.search();
            }
        },
        async search() {
            this.showlist = false;
            this.showspinner = true;
            if(this.$store.state.growthdb.length == 0){
                try{
                    var result = await axios.post('/api/getGrowthDB',{show:true});
                    this.$store.commit('growthdbupdate', result.data.growthdb);
                    this.searchResult(this.$store.state.growthdb);
                    this.showlist = true;
                    this.showspinner = false;
                }catch(e){
                    alert('불러오기 실패')
                    console.log(e)
                }                
            } else {
                // alert('수집된 상품DB가 없습니다. 로켓그로스 상품DB 수집을 진행해 주세요.(쿠팡wing 로그인 필수)')
                this.searchResult(this.$store.state.growthdb);
                this.showlist = true;
                this.showspinner = false;
            }
        },
        async searchResult(list){
            console.log(list)
            this.productLists = [];
            this.productListsinit = [];
            this.searchProducts = [];
            for(var i=0;i<list.length;i++){
                var f = list[i];
                if(!f.구매정보 || f.구매정보.length == 0){
                    f.구매정보 = [{
                        checked : false,
                        showspinner : false,
                        // SKUID : '',
                        바코드 : f.barcode,
                        상품명 : f.productName,
                        상품명init : f.productName,
                        구매링크 : undefined,
                        구매링크init : undefined,
                        옵션1_중국어 : undefined,
                        옵션2_중국어 : undefined,
                        옵션이미지: undefined,
                        매칭상태 : '매칭필요',
                        상품매칭 : {
                            옵션1_중국어 : [],
                            옵션2_중국어 : [],
                        },
                        구매재고 : '',
                        구매요청수량 : '',
                        판매구성수량 : 1,
                        묶음구성여부 : false,
                        묶음구성수량 : '',
                        묶음대상바코드 : '',
                        신고영문명 : '',
                        신고가격 : '',
                        HSCODE : '',
                        진행현황 : '구매전',
                        재고동봉 : true,
                        등록이미지 : f.optionImageUrl,
                        요청사항 : '요청등록',
                        reqtype : '제트배송',
                        sizeX : f.sizeInfo ? f.sizeInfo.width : 0,
                        sizeY : f.sizeInfo ? f.sizeInfo.length : 0,
                        sizeZ : f.sizeInfo ? f.sizeInfo.heigth : 0,
                        weight : f.sizeInfo ? f.sizeInfo.weight : 0,
                        요청내역 : {
                            default : true,
                            poarrange : false,

                            한글표시사항 : true,
                            barcode : true,
                            sticker : false,
                            sewing : false,
                            stamp : false,
                            tag : false,
                            reqcontent : '',
                            opp : false,
                            LDPE : false,
                            airpacking : false,
                            exportType : 'box',
                            destination : 'coupang',
                            masking : false,
                            auto : true,
                        },
                        한글표시사항 : {
                            제품명 : f.productName,
                            '수입원/판매원' : '',
                            제조원 : '',
                            제조국 : '',
                            내용량 : '',
                            '원료명및성분명(재질)' : '',
                            상품유형 : '',
                            사용시주의사항 : '',
                            사용기준 : '',
                            custom : '',
                        },
                        polists : [],
                        selectpo : '', 
                    }];
                }
                var obj = {
                    _id : f._id,
                    상품명 : f.productName,
                    바코드 : f.barcode,
                    노출ID : f.productId,
                    옵션ID : f.vendorItemId,
                    승인상태 : f.statusName,
                    옵션이미지 : f.optionImageUrl,

                    구매정보 : f.구매정보 ? f.구매정보 : f.purchaseInfo,
                    구매정보여부 : f.구매정보여부 ? f.구매정보여부 : f.purchaseInfoReg,
                    판매링크 : 'https://www.coupang.com/vp/products/' + f.productId + '?vendorItemId=' + f.vendorItemId,
                    아이템위너 : f.winner,
                    뱃지 : f.badge,

                    사이즈 : f.size,
                    사이즈정보 : f.sizeInfo,
                    판매가 : f.salePrice,

                    풀필먼트수수료 : f.fullfillmentFee,
                    입출고수수료 : f.warehouseFee,
                    판매수수료 : f.commision,
                    판매수수료율 : f.commisionRate,
                    수수료 : ((parseInt(f.fullfillmentFee) + parseInt(f.warehouseFee) + f.commision)*1.1).toFixed(0),

                    할인쿠폰 : f.coupon || 0,
                    매입정보 : f.매입정보 ? f.매입정보 : f.importInfo,
                    매입가 : f.매입가 ? f.매입가 : f.매입가,
                    마진 : f.마진,
                    마진율 : f.마진율,
                    ROI : f.ROI,
                    ROAS : f.ROAS
                    
                }

                this.changeCost(obj);
                this.changeMargin(obj);

                // if(f.vendorItemId == "85317106932"){
                //     console.log(obj)
                //     console.log(f)
                // }

                this.productLists.push(this.deepClone(obj))
                this.productListsinit.push(this.deepClone(obj))
            }
            this.total = this.productLists.length;
            this.뱃지없음 = this.productLists.filter(e => e.뱃지 == 'N').length;
            this.아이템루저 = this.productLists.filter(e => e.아이템위너 == 'N').length;
            this.구매정보없음 = this.productLists.filter(e => !e.구매정보여부).length;
            this.구매정보있음 = this.productLists.filter(e => e.구매정보여부).length;
            this.매입정보없음 = this.productLists.filter(e => !e.매입가).length;
            this.showlist = true;
            this.showspinner = false;
            this.showspinner1 = false;
            // this.productList = list;
        },
        showtotal(){
            this.showlist=false;
            setTimeout(() => {
            this.productLists = this.productListsinit;
            this.showlist = true;
            }, 1);
        },
        showa(){
            this.showlist=false;
            setTimeout(() => {
            this.productLists = this.productListsinit.filter(e => !e.아이템위너)
            this.showlist = true;
            }, 1);
        },        
        showb(){
            this.showlist=false;
            setTimeout(() => {
            this.productLists = this.productListsinit.filter(e => !e.뱃지)
            this.showlist = true;
            }, 1);
        }, 
        showc(){
            this.showlist=false;
            setTimeout(() => {
            this.productLists = this.productListsinit.filter(e => e.구매정보여부)
            this.showlist = true;
            }, 1);
        }, 
        showd(){
            this.showlist=false;
            setTimeout(() => {
            this.productLists = this.productListsinit.filter(e => !e.구매정보여부)
            this.showlist = true;
            }, 1);
        }, 
        showe(){
            this.showlist=false;
            setTimeout(() => {
            this.productLists = this.productListsinit.filter(e => !e.매입정보 || e.매입정보.length == 0)
            this.showlist = true;
            }, 1);
        }, 
        rematching(lists,list,index){
            if(!list.구매링크){
                alert('구매링크를 입력해 주세요')
                return
            }
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            this.matchoption1(lists,index,list.구매링크)
       },
       async matchoption1(lists,index,구매링크){
            lists[index].showspinner = true;
            lists[index].구매링크 = 구매링크;
           this.currentindex = index;
           // setTimeout(() => {this.sendmessage(구매링크);},1)
           var newObj = await this.confirmreq(lists[index]);
           // console.log(newObj)
           // this.showlist = false
           lists[index] = newObj;
           console.log(lists[this.currentindex])
           // this.showlist = true


           // axios.post('/api/reqpurchase/geturlinfo',{
           //     url : 구매링크
           // })
           // .then((res) => {
           //     if(res.data.요청결과 == 'success'){
           //         console.log(res.data.result)
           //         var obj = {}
           //         res.data.result.option1array.forEach(e => {
           //             obj = {
           //                 image : e.imageUrl,
           //                 optionname : e.name,
           //             }
           //             list.상품매칭.옵션1_중국어.push(obj)
           //         })
           //         res.data.result.option2array.forEach(e => {
           //             obj = {
           //                 image : e.imageUrl,
           //                 optionname : e.name,
           //             }
           //             list.상품매칭.옵션1_중국어.push(obj)
           //         })

           //     } else {
           //         alert('error777 :' + res.data.요청결과)
           //     }
           // })
           // .catch(console.log)
       },
       async confirmreq(obj){
        if(!obj.구매링크){
                obj.showspinner = false;
                return obj
            }
            obj.구매링크init = obj.구매링크;
            obj.매칭상태 = '매칭필요'
            var item = await this.getProductInfo(obj.구매링크);
            console.log(item)

            if(item.format_check == 'fail'){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = '구매불가';
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭필요';
                obj.showspinner = false;
                return obj
            }

            var option1array = [];
            var option2array = [];
            if(item.props_list && Object.keys(item.props_list).length > 0) {
                item.skus.sku.forEach(e => {
                    if (e.properties.includes(";")) {
                        var properties1 = e.properties.split(';')[0];
                        var properties2 = e.properties.split(';')[1];
                        if (!option1array.find(ele => ele.properties == properties1)) {
                            var name = e.properties_name.split(";" + properties2)[0].replace(properties1 + ":", "")
                            name = name.slice(name.search(":") + 1)
                            option1array.push({
                                properties: properties1,
                                name: name,
                                imageUrl: item.props_img[properties1] ? item.props_img[properties1] : '',
                            })
                        }
                        if (!option2array.find(ele => ele.properties == properties2)) {
                            name = e.properties_name.split(";" + properties2)[1].slice(1);
                            name = name.slice(name.search(":") + 1)
                            option2array.push({
                                properties: properties2,
                                name: name,
                                imageUrl: item.props_img[properties2] ? item.props_img[properties2] : '',
                            })
                        }
                    } else {
                        name = e.properties_name.replace(e.properties + ":", "");
                        name = name.slice(name.search(":") + 1)
                        option1array.push({
                            properties: e.properties,
                            name: name,
                            imageUrl: item.props_img[e.properties] ? item.props_img[e.properties] : '',
                        })
                    }
                })
            }

            if(option1array.length == 0){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = parseInt(item.num);
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭완료'
            } else {
                if(option2array.length == 0){
                    var imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                        obj.item = item
                    } else {
                        var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        console.log(option1properties)
                        obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == option1properties).quantity);
                        if(item.prop_imgs.prop_img.length > 0){
                            if(item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
                                imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                            } else {
                                if(obj.옵션이미지){
                                    imgUrl = obj.옵션이미지;
                                } else {
                                    imgUrl = '';
                                }
                            }

                        }
                        obj.옵션이미지 = imgUrl;
                        obj.매칭상태 = '매칭완료'
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션2_중국어 = '';
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                } else {
                    imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                        obj.item = item
                    } else {
                        option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
                            imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                        }
                        obj.옵션이미지 = imgUrl;
                        if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                            obj.옵션2_중국어 = undefined;
                            obj.구매재고 = 0;
                            obj.item = item
                        } else {
                            var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                            if (!item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties))) {
                                obj.옵션이미지 = undefined
                                obj.옵션1_중국어 = undefined;
                                obj.옵션2_중국어 = undefined;
                                obj.매칭상태 = '매칭필요'
                                obj.구매가격 = ''
                            } else {
                                obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).quantity);
                            }
                            if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option2properties)){
                                imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option2properties).url;
                            }
                            obj.옵션이미지 = imgUrl;
                            obj.매칭상태 = '매칭완료'
                        }
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = option2array;
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                }
            }
            if(!obj.등록이미지 && !option1array.find(e => e.imageUrl) && !option1array.find(e => e.imageUrl)){
                obj.등록이미지 = item.pic_url;
            }
            obj.showspinner = false;
            return obj
       },
       async getProductInfo(구매링크){
        var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            console.log(productNo)
            try{
                var res = await axios.post('/api/reqpurchase/getProductInfo',[productNo])
                return res.data.result[0].item
            }catch(e){
                console.log('err821' + e);
                return false
            }
       },
       pricechange(obj){
           this.change = true;
           if(obj.매칭상태 == '매칭필요'){
               obj.구매가격 = '';
               return
           }
           if(obj.item && obj.구매요청수량){
               var option1array = [];
               var option2array = [];
               var item = obj.item;
               if(item.props_list && Object.keys(item.props_list).length > 0){
                   Object.keys(item.props_list).forEach(e => {
                       if(e[0] == '0'){
                           option1array.push({
                               properties : e,
                               name : item.props_list[e].split(":")[1],
                               imageUrl : item.props_img[e] ? item.props_img[e] : '',
                           })
                       }
                       if(e[0] == '1'){
                           option2array.push({
                               properties : e,
                               name : item.props_list[e].split(":")[1],
                               imageUrl : item.props_img[e] ? item.props_img[e] : '',
                           })
                       }
                   })
               }

               if(option1array.length == 0){
                   if(item.priceRange){
                       for(var i=0;i<item.priceRange.length;i++){
                           obj.구매가격 = parseFloat(item.priceRange[i][1]);
                           if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                               break
                           }
                       }
                   } else {
                       obj.구매가격 = parseInt(item.price);
                   }
               } else {
                   if(option2array.length == 0){
                       if(item.priceRange){
                           for(i=0;i<item.priceRange.length;i++){
                               obj.구매가격 = parseFloat(item.priceRange[i][1]);
                               if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                                   break
                               }
                           }
                       } else {
                           obj.구매가격 = parseInt(item.price);
                       }
                   } else {
                       if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                           obj.구매가격 = '';
                       } else {
                           if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                               obj.구매가격 = '';
                           } else {
                               var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                               var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                               obj.구매가격 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).price);
                           }
                       }
                   }
               }
           }
       },
       reset(list){
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            list.구매링크 = undefined;
            list.구매링크init = '';
       },
       deleteindexfromarray(array,index){
            return array.slice(0,index).concat(array.slice(index+1))
        },
        deleteitem(lists,index,f){
            // this.showPurchase = false;
            lists.forEach(e => {
                e.묶음구성여부 = lists.length == 2 ? false : true;
                e.묶음구성수량 = lists.length == 2 ? "" : (lists.length-1)
            })
            lists.splice(index,1)
            console.log(lists)
            console.log(f)
            if(lists.length == 0){
                console.log(lists.length)
                lists.push({
                    checked : false,
                    showspinner : false,
                    // SKUID : '',
                    바코드 : f.바코드,
                    상품명 : f.상품명,
                    상품명init : f.상품명,
                    구매링크 : undefined,
                    구매링크init : undefined,
                    옵션1_중국어 : undefined,
                    옵션2_중국어 : undefined,
                    옵션이미지: undefined,
                    매칭상태 : '매칭필요',
                    상품매칭 : {
                        옵션1_중국어 : [],
                        옵션2_중국어 : [],
                    },
                    구매재고 : '',
                    구매요청수량 : '',
                    판매구성수량 : 1,
                    묶음구성여부 : false,
                    묶음구성수량 : '',
                    묶음대상바코드 : '',
                    신고영문명 : '',
                    신고가격 : '',
                    HSCODE : '',
                    진행현황 : '구매전',
                    재고동봉 : true,
                    등록이미지 : f.옵션이미지,
                    요청사항 : '요청등록',
                    reqtype : '제트배송',
                    sizeX : f.sizeX,
                    sizeY : f.sizeY,
                    sizeZ : f.sizeZ,
                    weight : f.weight,
                    요청내역 : {
                        default : true,
                        poarrange : false,

                        한글표시사항 : true,
                        barcode : true,
                        sticker : false,
                        sewing : false,
                        stamp : false,
                        tag : false,
                        reqcontent : '',
                        opp : false,
                        LDPE : false,
                        airpacking : false,
                        exportType : 'box',
                        destination : 'coupang',
                        masking : false,
                        auto : true,
                    },
                    한글표시사항 : {
                        제품명 : f.상품명,
                        '수입원/판매원' : '',
                        제조원 : '',
                        제조국 : '',
                        내용량 : '',
                        '원료명및성분명(재질)' : '',
                        상품유형 : '',
                        사용시주의사항 : '',
                        사용기준 : '',
                        custom : '',
                    },
                    polists : [],
                    selectpo : '', 
                })
                
            }
            console.log(lists)
            // lists = this.deleteindexfromarray(lists,index);
            // setTimeout(()=>{
            //     this.showPurchase = true;
            // },10)
            
        },
       matchclass(a){
           if(a == '매칭완료'){
               return 'badge badge-success';
           } else {
               return 'badge badge-warning';
           }
       },
       reqclass(a){
           if(a == '등록완료'){
               return 'badge badge-success';
           } else {
               return 'badge badge-warning';
           }
       },
       regclass(a){
           if(a.length > 0){
               return 'badge badge-success';
           } else {
               return 'badge badge-warning';
           }
       },
       option1matching(a,list){
            console.log(a)
            console.log(list)
           // this.showlist = false;
           if(a.imageUrl){
                // if(!list.등록이미지){
                    list.등록이미지 = a.imageUrl;
                // }
                list.옵션이미지 = a.imageUrl;
            }
            list.옵션1_중국어 = a.name;


            // if(this.checkstock){
            if(list.옵션2_중국어 == ''){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1).quantity);
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료';
            } else {
                if(list.옵션2_중국어 !== undefined){
                    properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                    var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                    list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                    list.구매sku = list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).sku_id;
                    if(list.구매재고 == 0){
                        alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                    } else {
                        alert('구매 가능한 재고수량 : ' + list.구매재고)
                    }
                    list.매칭상태 = '매칭완료';
                }
            }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            console.log(list)
            // this.showlist = true;
            // }
       },
       option2matching(a,list){
           // this.showlist = false;
           if(a.imageUrl){
                list.등록이미지 = a.imageUrl;
            }
            list.옵션2_중국어 = a.name;

            // if(this.checkstock){

            if(list.상품매칭.옵션1_중국어 && list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어)){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                list.구매sku = list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).sku_id;
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료'
            }

            // }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            console.log(list)
            // this.showlist = true;
       },
    }
}
</script>
<style scoped>

.my-class .dropdown-menu {
    transform: translate3d(0px, -30px, 0px) !important;
    max-height: 100px;
    width:400px;
    z-index:10000;
    overflow-y: auto;
}

</style>

